<template>
  <div class="app v">
    <div class="padding-08 no-flex" v-if="task">
      <div class="bold padding-08 fc-t">
        <span>{{task.proName}} / {{task.stageName}} / {{task.name}}</span>
        <br />
        <span class="fs-huge">进度记录列表</span>
      </div>
    </div>

    <van-pull-refresh v-model="refreshing" class="flex" style="overflow: hidden auto;" @refresh="reload">
      <van-list v-model="loading" :finished="finished" :immediate-check="false" @load="load">
        <progress-item v-for="item in store" :progress="item" :key="item.id" />
        <van-empty v-if="store.length == 0" description="暂无进度记录" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { list } from "@/api/project/progress";
import { getTaskById } from "@/api/project/task";
import ProgressItem from "./item";

export default {
  name: "ProjectProgress",
  components: { ProgressItem },
  computed: {
    taskId() {
      return this.$route.params.taskId;
    },
  },
  watch: {
    taskId: {
      handler: function () {
        this.reload();
        this.getTask();
      },
      immediate: true,
    },
  },
  data() {
    return {
      page: 1,
      size: 20,
      total: 0,
      store: [],
      refreshing: false,
      loading: false,
      finished: false,
      task: null,
    };
  },
  methods: {
    load() {
      list({
        current: this.page,
        size: this.size,
        taskId: this.taskId || "",
        orders: [{ column: "recTime", asc: false }],
      })
        .then((res) => {
          if (res) {
            this.store.push(...res.content);
            this.total = res.totalElements;
            this.finished = this.store.length >= this.total;
            this.page++;
          }
        })
        .finally(() => {
          this.loading = false;
          this.refreshing = false;
        });
    },
    reload() {
      this.page = 1;
      this.store = [];
      this.load();
    },
    getTask() {
      getTaskById(this.taskId).then((res) => {
        if (res) {
          this.task = res;
        }
      });
    },
  },
};
</script>

<style lang="scss" >
.container-progress {
  height: 100%;
  overflow-y: scroll;
  .item {
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  .header-progress {
    position: relative;
    line-height: 1.35rem;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    transition: all 0.3s;
    background-color: var(--background-color-lightest);

    // background-color: var(--background-color-lightest);
    // background-color: var(--selected-background-color-darken);
    // color: var(--selected-color-darken);
  }
  .name {
    font-size: 1.15rem;
    font-weight: bold;
    padding: 0.75rem;
  }
  .content {
    background-color: var(--background-color-lightest);
    border-radius: 0 0 10px 10px;
    img {
      display: none;
    }
  }
}
</style>