<template>
  <van-cell is-link :to="`/project/task/progress/detail/${progress.id}`" v-if="progress">
    <template #title>
      <van-tag class="gap-r-10">用时{{progress.costTime}}小时</van-tag>
      <van-tag :type="progress.remaining > 0 ? 'primary' : 'default'">剩余{{progress.remaining}}天</van-tag>
    </template>
    <template #label>
      <div class="van-multi-ellipsis--l2 fs-normal fc-t">{{progress.recInfo}}</div>

      <div class="gap-small">由&nbsp;{{progress.recUserName}}&nbsp;于&nbsp;{{progress.recTime}}&nbsp;记录</div>
    </template>
  </van-cell>
</template>

<script>
export default {
  props: {
    progress: Object,
  },
};
</script>